import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Section, SmallPart, LargePart } from 'components/Styled'
import { Button } from 'components'
import { useGlobalState } from 'contexts/GlobalState'

const { GoogleSpreadsheet } = require('google-spreadsheet')
const client = require('gsheet/client.json')
const { promisify } = require('util')

export default function Footer({ isMobile }) {
	const [websiteText] = useGlobalState('websiteText')
	const [selectedLanguage] = useGlobalState('selectedLanguage')

	return (
		<>
			<Section className='py-12 pl-5 footer bg-gray'>
				<SmallPart style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
					<div className='pr-12'>
						<h1 className='text-4xl mb-14 font-semibold'>{websiteText.saiunamidia_titulo[selectedLanguage]}</h1>
					</div>
					<div></div>
				</SmallPart>

				<LargePart className='flex flex-col md:flex-row md:pl-10 md:justify-between md:pr-80 space-y-8 md:space-y-0 items-stretch'>
					<div className='space-y-12' style={{ columnCount: isMobile ? 1 : 2, columnGap: 20, flex: 1 }}>
						<div>
							<a href={websiteText.artigo_1_link['pt']} target='_blank' className='text-lg font-bold block hover:opacity-60' rel='noopener noreferrer'>
								{`${websiteText.artigo_1_titulo['pt']} ↗`}
							</a>
							<span className='text-gray-400 text-sm'>{`${websiteText.artigo_1_veiculo['pt']} — ${websiteText.artigo_1_data['pt']}`}</span>
						</div>

						<div>
							<a href={websiteText.artigo_2_link['pt']} target='_blank' className='text-lg font-bold block hover:opacity-60' rel='noopener noreferrer'>
								{`${websiteText.artigo_2_titulo['pt']} ↗`}
							</a>
							<span className='text-gray-400 text-sm'>{`${websiteText.artigo_2_veiculo['pt']} — ${websiteText.artigo_2_data['pt']}`}</span>
						</div>

						<div>
							<a href={websiteText.artigo_3_link['pt']} target='_blank' className='text-lg font-bold block hover:opacity-60' rel='noopener noreferrer'>
								{`${websiteText.artigo_3_titulo['pt']} ↗`}
							</a>
							<span className='text-gray-400 text-sm'>{`${websiteText.artigo_3_veiculo['pt']} — ${websiteText.artigo_3_data['pt']}`}</span>
						</div>

						<div>
							<a href={websiteText.artigo_4_link['pt']} target='_blank' className='text-lg font-bold block hover:opacity-60' rel='noopener noreferrer'>
								{`${websiteText.artigo_4_titulo['pt']} ↗`}
							</a>
							<span className='text-gray-400 text-sm'>{`${websiteText.artigo_4_veiculo['pt']} — ${websiteText.artigo_4_data['pt']}`}</span>
						</div>
					</div>
				</LargePart>
			</Section>

			<Form />

			<Section className='py-12 pl-5 footer bg-gray-900 text-white'>
				<SmallPart>
					<h5 className='opacity-70 pb-8'>{websiteText.assinatura_texto[selectedLanguage]}</h5>
				</SmallPart>

				<LargePart className='footer_links flex flex-col md:flex-row md:pl-10 md:justify-between md:pr-80 space-x-8 md:space-y-0 items-stretch'>
					<div className='space-y-12' style={{ flex: 1 }}>
						<div>
							<h5 className='opacity-70'>{websiteText.contato_texto[selectedLanguage]}</h5>
							<a href='mailto:comunidade@olabi.org.br' className='text-lg font-bold hover:opacity-60 text-blue-300'>
								comunidade@olabi.org.br ↗
							</a>
						</div>
						<div>
							<h5 className='opacity-70'>{websiteText.links_texto[selectedLanguage]}</h5>
							<div className='footer_list space-y-2' style={{ columnCount: 2, columnGap: 20 }}>
								<a target='_blank' className='hover:opacity-60 text-blue-300' href='https://www.pretalab.com/' rel='noopener noreferrer'>
									PretaLab ↗
								</a>
								<a target='_blank' className='hover:opacity-60 text-blue-300' href='https://www.aprendacomumaavo.com.br/' rel='noopener noreferrer'>
									Aprenda Com Uma Avó ↗
								</a>
								<a target='_blank' className='hover:opacity-60 text-blue-300' href='https://www.bloco.cc/' rel='noopener noreferrer'>
									BLOCO ↗
								</a>
								<a target='_blank' className='hover:opacity-60 text-blue-300' href='https://protegebr.olabi.org.br/' rel='noopener noreferrer'>
									ProtegeBR ↗
								</a>
								<a target='_blank' className='hover:opacity-60 text-blue-300' href='https://www.computacaosemcao.com.br' rel='noopener noreferrer'>
									Computação Sem Caô ↗
								</a>
								<a target='_blank' className='hover:opacity-60 text-blue-300' href='https://oifuturo.org.br/evento/festival-oi-futuro/' rel='noopener noreferrer'>
									Festival Oi Futuro ↗
								</a>
								<a target='_blank' className='hover:opacity-60 text-blue-300' href='https://www.pretalab.com/pretapode' rel='noopener noreferrer'>
									PretaPod(e) ↗
								</a>
								<a target='_blank' className='hover:opacity-60 text-blue-300' href='https://www.maissessenta.com.br/' rel='noopener noreferrer'>
									60+ Arte e Tecnologia ↗
								</a>
								<p>Códigos Negros</p>
								<p>ConverseComOutrasIdeias</p>
								<p>Makerspace Pop Up</p>
								<p>Minas de Dados</p>
								<p>Pequenos Inventores</p>
								<p>Hack The Brain</p>
								<p>Remakers</p>
								<p>Sul-Sul</p>
								<p>Ciência Cidadã</p>
								<p>Rodada Hacker</p>
								<p>
									Inovação Distribuída e<br /> Ativismo no Brasil
								</p>
								<p>Gambiarra Favela Tech</p>
							</div>
						</div>
					</div>
				</LargePart>
			</Section>

			<div id='intro' className='section-cc footer'>
				<div className='content-wrapper'>
					<div>
						<img src='https://assets-global.website-files.com/5b05e2e1bfcfaa4f92e2ac3a/5b351ba83607a317df5b639b_cc.logo.white%20(1).svg' width='121' alt='' className='image-12' />
						<h5>{websiteText.creativecommons_texto[selectedLanguage]}</h5>
					</div>
				</div>
			</div>
		</>
	)
}

const Form = () => {
	const [websiteText] = useGlobalState('websiteText')
	const [selectedLanguage] = useGlobalState('selectedLanguage')
	const { register, handleSubmit } = useForm()
	const [email, setEmail] = useState('')
	const [formSubmitted, setFormSubmitted] = useState(false)

	const doc = new GoogleSpreadsheet('1A8zaDpOJWItZHfmhTduBB-XEuYk63mkZJm1DG3hi_jg')

	const onSubmit = async ({ email }) => {
		await doc.useServiceAccountAuth(client)
		await doc.loadInfo()
		const sheet = doc.sheetsByIndex[0]
		sheet.addRow()
		setFormSubmitted(true)
	}

	return (
		<Section className='py-12 pl-5 bg-gray-800 text-white'>
			<SmallPart style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
				<div className='pr-12'>
					<h1 className='text-4xl mb-10 font-semibold'>Newsletter</h1>
				</div>
				<div></div>
			</SmallPart>

			<LargePart className='flex flex-col md:pl-10 md:justify-between md:pr-80 space-y-4 items-stretch'>
				<p className='max-w-sm'>{!formSubmitted ? websiteText.newsletter_texto[selectedLanguage] : <span className='text-lg font-bold block'>Seu email foi inscrito com sucesso</span>}</p>
				{!formSubmitted && (
					<form style={{ flex: 1 }} onSubmit={handleSubmit(onSubmit)}>
						<input ref={register} className='newsletter-input rounded-md' placeholder='digite seu email' type='email' name='email' id='email' value={email} onChange={(event) => setEmail(event.target.value)} required={true} />
						<Button className='button contact' type='submit'>
							enviar
						</Button>
						{/* <span style={{ marginLeft: '20px', padding: '10px' }}>error message</span> */}
					</form>
				)}
			</LargePart>
		</Section>
	)
}
