import { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Logo, Cover, Footer, People, Partners, Projects, About, Loader } from 'components'
import { useGlobalState } from 'contexts/GlobalState'

const { GoogleSpreadsheet } = require('google-spreadsheet')
const client = require('gsheet/client.json')
const { promisify } = require('util')

const doc = new GoogleSpreadsheet('1nQ7Panhc5gC4ThVJVjEIK1AjpcojTKULL-mFXre2HvY')

function App() {
	const isMobile = useMediaQuery({ maxDeviceWidth: 767 })
	const [websiteText, setWebsiteText] = useGlobalState('websiteText')

	useEffect(() => {
		async function fetchMyAPI() {
			await doc.useServiceAccountAuth(client)
			await doc.loadInfo()
			const sheet = doc.sheetsByIndex[0]
			const rows = await sheet.getRows({ offset: 0 })
			let textsFetched = {}
			rows.forEach((row) => {
				const sheetRow = {
					[row.id]: {
						pt: row.portuguese,
						en: row.english,
					},
				}
				textsFetched = { ...textsFetched, ...sheetRow }
			})
			setWebsiteText(textsFetched)
		}
		fetchMyAPI()
	}, [])

	useEffect(() => {
		;(function (d) {
			var s = d.createElement('script')
			s.setAttribute('data-account', 'P2mDLuGnbV')
			s.setAttribute('src', 'https://cdn.userway.org/widget.js')
			;(d.body || d.head).appendChild(s)
		})(document)
	}, [])

	if (!websiteText) {
		return <Loader />
	}

	return (
		<>
			{/* <Menu /> */}
			{/* <button onClick={() => setMenuIsOpen(!menuIsOpen)}>open menu</button> */}
			<Logo />
			<Cover isMobile={isMobile} />
			<About isMobile={isMobile} />
			<Projects />
			<People isMobile={isMobile} />
			<Partners />
			<Footer isMobile={isMobile} />
			<AccessibilityWidget />
		</>
	)
}

function AccessibilityWidget() {
	useEffect(() => {
		;(function (d) {
			var s = d.createElement('script')
			s.setAttribute('data-account', 'P2mDLuGnbV')
			s.setAttribute('src', 'https://cdn.userway.org/widget.js')
			;(d.body || d.head).appendChild(s)
		})(document)
	}, [])

	return <></>
}

export default App
